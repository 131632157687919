<template>
 
                <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;min-width:max-content;width:calc(100% - 32px)">
                    <a-layout style="height:100%;background:#fff;padding:20px;">
                        <a-form-item label="" style="margin-bottom:10px;"
                                     :label-col="formItemLayout.labelCol"
                                     :wrapper-col="formItemLayout.wrapperCol">
                            <a-form layout='inline'>
                                <!-- <a-form-item label="参与类型">
                                    <a-radio-group :default-value="PartType" v-model="PartType" @change="loadData">
                                        <a-radio-button value="-1">全部</a-radio-button>
                                        <a-radio-button value="0">阅读</a-radio-button>
                                        <a-radio-button value="1">分享</a-radio-button>
                                        <a-radio-button value="2">推广</a-radio-button>
                                    </a-radio-group>
                                </a-form-item> -->      <a-space>
                                <label>参与人：</label>
                                <a-input id="Name"
                                         contextmenu="2455"
                                         :value="Name"
                                         style="width:260px"
                                         placeholder="请输入参与人名称或电话"
                                         @change="name_change"></a-input>
                                <a-button type="primary" @click="name_change2" style="margin-left:20px;">查询</a-button>
                                <a-button style="margin-top:5px;margin-left:20px;" type="primary" @click="exportdata">导出</a-button>
                                </a-space>
                            </a-form>
                        </a-form-item>
                        <a-table bordered :columns="columns" rowKey="ID" :data-source="PoolSendDetailList" :pagination="pagination" @change="pagerChange">
                            <span slot="PartType" slot-scope="text,record">{{ShowPartType(record.PartType)}}</span>
                            <span slot="name_slotuser" slot-scope="text,record">
                                <a @click="jumpuser(record)">{{record.VisterName}}</a>
                            </span>
                            <span slot="name_slotshare" slot-scope="text,record">
                                <a @click="jumpshare(record)">{{record.ShareName}}</a>
                            </span>
                            <span slot="CheckState" slot-scope="text,record">
                                <label v-if="record.CheckState==1">已核销</label>
                                <label v-if="record.CheckState==2">已过期</label>
                                <a-button type="link" size="small" @click="CheckM2ActivityAwardPoolSendDetail(record)" v-if="record.CheckState==0">去核销</a-button>
                            </span>
                        </a-table>
                    </a-layout>
                </div>
    
    
</template>
<script type="text/javascript">
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "M2Material_PrizeSendDetail",
        data() {
            return {
                PoolSendDetailList: [],
                columns: [
                    {
                        title: "参与人头像",
                        align: "center",
                        customRender: (text, item) => {
                            var img = item.VisterImg;
                            if (img != null && img != "") {
                                return <div><img src={img} width="60" height="60" /></div>
                            } else {
                                return <div><img src="/image/tjwz.png" width="60" height="60" /></div>
                            }
                        }
                    },
                    {
                        title: "姓名",
                        dataIndex: "VisterName",
                        scopedSlots: { customRender: "name_slotuser" },
                    },
                    {
                        title: "手机",
                        dataIndex: "VisterPhone",
                        width: 160
                    },
                    {
                        title: "分享人",
                        dataIndex: "ShareName",
                        scopedSlots: { customRender: "name_slotshare" },
                    },
                    {
                        title: "参与类型",
                        scopedSlots: { customRender: 'PartType' },
                    },
                    {
                        title: "奖品名称",
                        dataIndex: "PoolName",
                    },
                    {
                        title: "奖品类型",
                        align: "center",
                        customRender: (text, item) => {
                            var displayTxt = "";
                            if (item.PoolType == 1)
                                displayTxt = "优惠券";
                            if (item.PoolType == 2)
                                displayTxt = "实物奖品";
                            if (item.PoolType == 3)
                                displayTxt = "现金返现";
                            return displayTxt;
                        }
                    },
                    {
                        title: "奖品金额",
                        dataIndex: "PoolCash",
                        customRender: (text, item) => {
                            var displayTxt = "";
                            if (item.PoolType == 1)
                                displayTxt = item.PoolCash + "";
                            if (item.PoolType == 2)
                                displayTxt = "";
                            return displayTxt;
                        }
                    },
                    {
                        title: "领取时间",
                        customRender: (text, item) => {
                            var temp = new Date(util.TimeStamptoDateTime(item.InputTime, 'yyyy-MM-dd hh:mm:ss'));
                            return util.formatDate3(temp);
                        }
                    },
                    {
                        title: "过期时间",
                        customRender: (text, item) => {
                            var temp = new Date(util.TimeStamptoDateTime(item.ExpirationTime, 'yyyy-MM-dd hh:mm:ss'));
                            return util.formatDate3(temp);
                        }
                    },
                    {
                        title: "核销状态",
                        align: "center",
                        scopedSlots: { customRender: 'CheckState' },
                    },
                    {
                        title: "核销时间",
                        align: "center",
                        customRender: (text, item) => {
                            var displayTxt = "";
                            if (item.CheckState == 0)
                                displayTxt = " ";
                            if (item.CheckState == 1)
                                displayTxt = util.TimeStamptoDateTime(item.CheckTime, 'yyyy-MM-dd hh:mm:ss');
                            return displayTxt;
                        }
                    },
                    {
                        title: "核销人员",
                        align: "center",
                        customRender: (text, item) => {
                            var displayTxt = "";
                            if (item.CheckState == 0)
                                displayTxt = " ";
                            if (item.CheckState == 1)
                                displayTxt = item.CheckUserName;
                            return displayTxt;
                        }
                    },
                ],
                Tablelocale: {
                    emptyText: "暂无数据"
                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 1 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                }, config: {
                    rules: [
                        { type: "object", required: true, message: "Please select time!" }
                    ]
                },
                rangeConfig: {
                    rules: [
                        { type: "array", required: true, message: "Please select time!" }
                    ]
                },
                pagination: {
                    total: 0,
                    defaultPageSize: 10,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                PartTypeEnum: [],//奖励类型
                PartType: "-1",
                Name: "",
            };
        },
        methods: {
            //获取参与类型枚举
            getPartTypeEnum: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.MarketingModule",//程序集名称
                        TypeName: "Welcome.MarketingModule.Enumerate.EnumActivityPartType"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.PartTypeEnum = response.data
                    }
                }
                http.Post(op);
            },
            ShowPartType: function (Key) {//枚举从0开始，且连续，可以使用此方法，效率高
                var Self = this;
                if (Self.PartTypeEnum[Key])
                    return Self.PartTypeEnum[Key].Name
                else
                    return "";
            },
            jumpuser: function (data) {

                var self = this;
                self.$router.push({
                    name: "OperationsDetail", query: {
                        ID: data.UserID
                    }
                })
            },
            jumpshare: function (data) {

                var self = this;
                self.$router.push({
                    name: "OperationsDetail", query: {
                        ID: data.ShareUserID
                    }
                })
            },
            exportdata: function () {
                var self = this
                var op = {
                    url: "/MarketingModule/M2Activity/ExcelSendDetail",
                    data: {
                        MaterialID: this.$route.query.ID,
                        AwardPoolID: this.$route.query.AwardPoolID,
                        PageIndex: self.pagination.current,
                        PartType: self.PartType,
                    },//下载接口参数
                    FileName: '领取优惠券名单.xlsx',//下载文件重命名
                    ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'//文件Mine类型
                };
                http.DownLoadFile(op);
            },
            //页面跳转事件
            onPaginationChange(e) {
                this.pagination.current = e
                this.loadData();
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.loadData();
            },
            loadData() {
                var self = this;
                var op = {
                    url: "/MarketingModule/M2Activity/GetPoolSendDetailListM",
                    data: {
                        MaterialID: this.$route.query.ID,
                        AwardPoolID: this.$route.query.AwardPoolID,
                        PageIndex: self.pagination.current,
                        PartType: self.PartType,
                        Name: self.Name,
                        CheckStatus: this.$route.query.Status,
                    },
                    OnSuccess: function (res) {
                        res.data.Data.forEach(element => {
                            element.PoolCash = parseFloat(element.PoolCash).toFixed(2);
                        });
                        self.PoolSendDetailList = res.data.Data;
                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            name_change: function (item) {
                var self = this;
                self.Name = item.target.value;
                self.pagination.current = 1;
                self.loadData();
            },
             name_change2: function (item) {
                var self = this;
                self.name = item.target.value;
                self.pagination.current = 1;
                self.loadData();
            },

            CheckM2ActivityAwardPoolSendDetail(e) {
                var self = this;
                this.$confirm({
                    title: '提示',
                    content: '是否核销',
                    okText: "确定",
                    cancelText: '取消',
                    onOk() {
                        var op = {
                            url: "/MarketingModule/M2Activity/CheckM2ActivityAwardPoolSendDetail",
                            data: {
                                ID: e.ID,
                                AwardPoolID: e.AwardPoolID,
                                MaterialID: e.MaterialID
                            },
                            OnSuccess: function (data) {
                                console.log(data);
                                self.loadData();
                                self.$message.success("核销成功");
                            }
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            },
        },
        mounted() {
            this.loadData();
            this.getPartTypeEnum();
        }
    };
</script>



